<template>
	<!-- <Alert v-if="isLoading" :title="$t('alert.loading')"></Alert> -->
	<div v-if="currentCart.id">
		<Popup v-if="showServicePopup" @close="onCloseServicePopup" @submit="onCloseServicePopup" :title="$t('popupSurcharge.surcharge')" :confirmText="$t('popupSurcharge.complete')">
			<div v-for="service in cart.surcharges" :key="service.id" class="mt-5 h-full overflow-scroll text-secondary">
				<div>{{ $t('popupSurcharge.name') }}: {{ service.name[currentLang] }}</div>
			</div>
		</Popup>

		<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>

		<Toolbar @clickRight="$router.push('/cart/shipment')">
			<p>{{ $t('cart.cart') }}</p>
			<!-- <template v-slot:end>預約</template> -->
		</Toolbar>

		<div class="p-5 pb-28">
			<!-- 選擇時間 -->
			<div class="mb-2 flex justify-between">
				<p class="text-xl font-bold">{{ $t('cart.deliveryTime') }}</p>
			</div>
			<div class="text-md mb-5 inline-flex w-full items-center justify-between rounded-xl bg-light-blue p-4 font-bold text-primary" @click="$router.push('/cart/shipment')">
				<div v-if="cart.method.type === 'ONSITE'">
					<p>{{ $t('cart.onSite') }}</p>
				</div>
				<div v-else-if="cart.method.type === 'DELIVERY'">
					<div v-if="cart.deliverySetting">
						<p class="text-xl font-black" :class="cart.deliverySetting.time ?? 'text-red-500'">{{ cart.deliverySetting.time ? moment(cart.deliverySetting.time).format('YYYY-MM-DD HH:mm') : $t('cart.timeError') }}</p>
						<p :class="cart.deliverySetting.address?.addressLine1 ?? 'text-red-500'">{{ cart.deliverySetting.address?.addressLine1 ?? $t('cart.addressError') }}</p>
					</div>
					<p v-else class="text-xl font-black text-red-500">{{ $t('cart.shippingError') }}</p>
				</div>
				<div v-else>
					<p class="text-xl font-black">{{ moment(cart.pickupSetting?.time).format('YYYY-MM-DD HH:mm') }}</p>
				</div>
				<p class="whitespace-nowrap">{{ $t('cart.editTime') }}</p>
			</div>

			<!-- 購物車內容 -->
			<div class="flex w-full flex-col gap-3">
				<p class="text-xl font-bold">MyJetmall</p>
				<div class="mb-5 rounded-xl bg-light-blue">
					<template v-for="(product, index) in cartProducts" :key="index">
						<div v-if="product.status !== 'CANCELED'" class="grid w-full grid-cols-8 overflow-hidden rounded-xl p-3">
							<div v-if="getProduct(product).imageUrls[0]" class="col-span-2 mr-5 h-20 w-20" @click="setCurrentProduct(product, index)">
								<img class="h-full rounded-xl object-cover" :src="getProduct(product)?.imageUrls[0]" alt="" loading="lazy" />
							</div>
							<div class="inline-grid gap-2 px-2" :class="getProduct(product).imageUrls[0] ? 'col-span-6' : 'col-span-8'">
								<div class="flex justify-between" @click="setCurrentProduct(product, index)">
									<div>
										{{ product.name[currentLang] }}
									</div>
									<div>
										<p v-if="getVariantById(product.variantId).value.isCustomPrice">{{ product.sellingPrice[saleMethod] > 0 ? `$${product.sellingPrice[saleMethod]}` : $t('cart.quoteSeparately') }}</p>
										<p v-else-if="getVariantById(product.variantId).value.originalPrice[saleMethod] > product.sellingPrice[saleMethod]" class="flex items-center gap-2 text-red-500">
											<del class="text-xs text-muted">${{ getVariantById(product.variantId).value.originalPrice[saleMethod] }}</del
											>${{ product.sellingPrice[saleMethod] }}
										</p>
										<p v-else>${{ product.sellingPrice[saleMethod] }}</p>
									</div>
								</div>
								<div class="flex justify-between">
									<div class="flex flex-col text-sm text-secondary">
										<!-- <div>{{ product.brandName }}</div> -->
										<div>{{ getVariantById(product.variantId).value.sku }}</div>
									</div>
									<div class="flex h-8 w-28 justify-between rounded-full bg-white px-2 text-primary">
										<!-- 減少購物車數量 -->
										<button @click="minusQuantity(product, index)">
											<Icon class="h-3 w-3" icon="minus" />
										</button>
										<!-- 購物車數量 -->
										<p class="text-md my-auto">{{ product.orderedQuantity }}</p>
										<!-- 增加購物車數量 -->
										<button @click="addQuantity(product, index)">
											<Icon class="h-3 w-3" icon="plus" />
										</button>
									</div>
								</div>
								<div class="h-full text-right text-sm text-red-500">
									<button @click="removeProductFromCart(index)">{{ $t('cart.removeTime') }}</button>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>

			<!-- 選擇優惠 -->
			<div class="mb-2 flex justify-between">
				<p class="text-xl font-bold">{{ $t('cart.vouchers') }}</p>
			</div>
			<template v-for="(discount, index) in cartDiscounts" :key="index">
				<div v-if="discount.status !== 'CANCELED'" class="mb-2 inline-flex w-full items-center justify-between rounded-xl bg-light-blue p-4">
					<div class="text-md" @click="setCurrentDiscount(discount, index)">
						{{ discount.name[currentLang] }}
					</div>
					<div class="text-red-500" @click="removeDiscountFromCart(index)" :disabled="isLoading">{{ $t('cart.removeTime') }}</div>
				</div>
			</template>

			<div v-if="cartDiscounts.length === 0" class="mb-5 inline-flex w-full items-center justify-between rounded-xl bg-light-blue p-4" @click="$router.push('/discounts')">
				<div class="text-sm text-muted">{{ $t('cart.selectVoucher') }}</div>
				<div class="text-primary">
					<Icon class="h-5 w-5" icon="plus" />
				</div>
			</div>

			<!-- 積分兌換 -->
			<div class="mb-2 flex items-end justify-between">
				<p class="text-xl font-bold">{{ $t('cart.points') }}</p>
				<p class="text-sm text-muted">{{ $t('cart.pointsBalance') }} {{ customer.pointBalance }}P</p>
			</div>
			<div class="mb-5 inline-flex w-full items-center justify-between rounded-xl bg-light-blue p-4 text-primary">
				<!-- 減少積分數量 -->
				<button>
					<Icon class="h-5 w-5" icon="minus" @click="minusUsedPoints" />
				</button>
				<!-- 積分數量 -->
				<div class="text-xl"><input type="number" min="1" max="9999" inputmode="numeric" pattern="[0-9]*" v-model="cart.usedPoints" @change="updatedUsedPoints(cart.usedPoints)" class="mx-auto w-20 bg-transparent text-center" /><span>P</span></div>
				<!-- 增加積分數量 -->
				<button>
					<Icon class="h-5 w-5" icon="plus" @click="addUsedPoints" />
				</button>
			</div>

			<!-- 購物車總計 -->
			<div class="mb-5 flex flex-col gap-2 rounded-xl bg-light-blue p-5">
				<div class="grid grid-cols-8">
					<div class="col-span-4">{{ $t('cart.merchandiseSubtotal') }}</div>
					<div class="col-span-4 text-right">${{ cart.productTotal }}</div>
				</div>
				<div v-if="cart.discountTotal" class="grid grid-cols-8">
					<div class="col-span-4">{{ $t('cart.discountSubtotal') }}</div>
					<div class="col-span-4 text-right">${{ cart.discountTotal }}</div>
				</div>
				<div v-if="cart.surchargeTotal" class="grid grid-cols-8">
					<div class="col-span-4 flex items-center space-x-2" @click="showServicePopup = !showServicePopup" @close="onCloseServicePopup">
						<Icon class="w-4 text-secondary" icon="circleExclamation"></Icon>
						<p>{{ $t('cart.deliveryFee') }}</p>
					</div>
					<div class="col-span-4 text-right">${{ cart.surchargeTotal }}</div>
				</div>
				<div v-if="cart.usedPoints" class="grid grid-cols-8">
					<div class="col-span-4">{{ $t('cart.usedPoints') }}</div>
					<div class="col-span-4 text-right">{{ cart.usedPoints }}P</div>
				</div>
				<div class="col-span-8 border-b-2 p-1"></div>
				<div class="grid grid-cols-8">
					<div class="col-span-4">{{ $t('cart.totalAmount') }}</div>
					<div class="col-span-4 text-right">${{ cart.grandTotal }}</div>
				</div>
				<div class="grid grid-cols-8">
					<div class="col-span-4">{{ $t('cart.estimatedPointsEarning') }}</div>
					<div class="col-span-4 text-right">{{ cart.earnedPoints }}P</div>
				</div>
			</div>

			<!-- 輸入備註 -->
			<div class="mb-2 flex items-end justify-between">
				<p class="text-xl font-bold">{{ $t('cart.remarks') }}</p>
				<p class="text-sm text-muted">{{ $t('cart.optional') }}</p>
			</div>
			<div class="mb-5 rounded-xl bg-light-blue p-4">
				<textarea :value="cart.remark" rows="3" class="text-md h-16 w-full bg-transparent placeholder-muted" :placeholder="$t('cart.specialRequest')" @change="setCartRemark($event.target.value)" />
			</div>

			<!-- 是否預先拆裝 -->
			<div v-if="cart.pickupSetting || cart.deliverySetting" class="mb-5 grid grid-cols-8 items-center gap-5 rounded-2xl bg-light-grey p-5">
				<div class="col-span-6">
					<label class="text-lg text-primary">{{ $t('cart.isUnpacked') }}</label>
				</div>
				<div class="relative col-span-2 mx-auto mr-2 inline-block w-12 select-none align-middle transition duration-200 ease-in">
					<input :checked="cart.pickupSetting?.isUnpacked || cart.deliverySetting?.isUnpacked" name="cart.isUnpacked" @click="toggleIsUnpacked" type="checkbox" class="absolute block h-6 w-6 cursor-pointer appearance-none rounded-full border-4 border-gray-300 bg-white checked:right-0 checked:border-primary" />
					<label class="block h-6 cursor-pointer overflow-hidden rounded-full" :class="cart.pickupSetting?.isUnpacked || cart.deliverySetting?.isUnpacked ? 'bg-primary' : 'bg-gray-300'" for="cart.isUnpacked"></label>
				</div>
			</div>

			<!-- 付款 -->
			<div>
				<Button v-if="isOnHold" :class="{ 'bg-muted': isLoading }" @click="callSupport()">
					{{ $t('orderStatusActions.ON_HOLD') }}
				</Button>
				<Button v-else :class="{ 'bg-muted': isLoading }" :loading="isLoading" :disabled="isLoading" @click="checkoutCart">{{ $t('cart.payment') }} ${{ cart.grandTotal }}</Button>
			</div>
		</div>
	</div>
	<div v-else class="h-96 w-full">
		<Toolbar @clickRight="$router.push('/cart/shipment')">
			<p>{{ $t('cart.cart') }}</p>
			<!-- <template v-slot:end>{{ $t('cart.reserve') }}</template> -->
		</Toolbar>
		<p class="flex h-full items-center justify-center">{{ $t('cart.emptyCart') }}</p>
	</div>
</template>

<script>
// import { appType } from '../../package.json'
import { storeToRefs } from 'pinia'
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import moment from 'moment'
import { useSharedStore } from '@/store/shared'
import { useCatalogStore } from '@/store/catalog'
import { useCartStore } from '@/store/cart'
import { useCustomerStore } from '@/store/customer'
import { useShopStore } from '@/store/shop'
import { useSaleStore } from '@/store/sale'
import { Browser } from '@capacitor/browser'
import { useI18n } from 'vue-i18n'
import { saleApi } from 'gox-sdk'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()
		const { currentLang, chatMessage, paypalData, config } = storeToRefs(useSharedStore())
		const { catalog, product, voucher, vouchers } = storeToRefs(useCatalogStore())
		const { getVariantById } = useCatalogStore()
		const { currentShop } = storeToRefs(useShopStore())
		const { currentProduct, currentCart, saleMethod } = storeToRefs(useCartStore())
		const { setCurrentCartMethod, resetCart } = useCartStore()
		const { currentCustomer } = storeToRefs(useCustomerStore())
		const { getCurrentSale } = useSaleStore()
		const { t, locale } = useI18n({ useScope: 'global' })
		const deliverySetting = ref('')
		const cart = currentCart
		const cartProducts = computed(() => cart.value.products)
		const cartDiscounts = computed(() => cart.value.vouchers)
		const customer = currentCustomer.value
		const deliveryType = ref({
			MYJETMALL_BAC_DELIVERY: t('deliveryType.MYJETMALL_BAC_DELIVERY'),
			MYJETMALL_DELIVERY: t('deliveryType.MYJETMALL_DELIVERY'),
			SF_LOCKER_DELIVERY: t('deliveryType.SF_LOCKER_DELIVERY'),
			SF_EXPRESS_DELIVERY: t('deliveryType.SF_EXPRESS_DELIVERY'),
		})
		onMounted(async () => {
			deliverySetting.value = config.value.deliverySetting
			if (cart.value.deliverySetting) {
				const configDeliverySetting = cart.value.deliverySetting.address.addressLine1
				for (let i = 0; i < deliverySetting.value.address.length; i++) {
					if (deliverySetting.value.address[i].EN === configDeliverySetting) {
						if (locale.value === 'EN') return
						if (locale.value === 'TC') cart.value.deliverySetting.address.addressLine1 = deliverySetting.value.address[i].TC
						if (locale.value === 'SC') cart.value.deliverySetting.address.addressLine1 = deliverySetting.value.address[i].SC
						await saleApi.updateSaleDeliverySetting(cart.value.id, { deliverySetting: cart.value.deliverySetting })
					}
					if (deliverySetting.value.address[i].SC === configDeliverySetting) {
						if (locale.value === 'SC') return
						if (locale.value === 'EN') cart.value.deliverySetting.address.addressLine1 = deliverySetting.value.address[i].EN
						if (locale.value === 'TC') cart.value.deliverySetting.address.addressLine1 = deliverySetting.value.address[i].TC
						await saleApi.updateSaleDeliverySetting(cart.value.id, { deliverySetting: cart.value.deliverySetting })
					}
					if (deliverySetting.value.address[i].TC === configDeliverySetting) {
						if (locale.value === 'TC') return
						if (locale.value === 'EN') cart.value.deliverySetting.address.addressLine1 = deliverySetting.value.address[i].EN
						if (locale.value === 'SC') cart.value.deliverySetting.address.addressLine1 = deliverySetting.value.address[i].SC
						await saleApi.updateSaleDeliverySetting(cart.value.id, { deliverySetting: cart.value.deliverySetting })
					}
				}
			}
		})
		const isOnHold = computed(() => {
			const isCustomPriceProduct = cart.value.products.filter((product) => product.sellingPrice[saleMethod.value] == 0 && product.status !== 'CANCELED')
			if (isCustomPriceProduct.length > 0) return true
			return false
		})
		const isValid = computed(() => {
			if (cart.value.products.length === 0) return false
			if (cart.value.shipment.type === 'DELIVERY') {
				switch (cart.value.shipment.deliveryType) {
					case 'MYJETMALL_BAC_DELIVERY': {
						if (!cart.value.shipment.date || !cart.value.shipment.time) return false
						break
					}
					case 'MYJETMALL_DELIVERY': {
						if (!cart.value.shipment.date || !cart.value.shipment.time || !cart.value.shipment.address) return false
						break
					}
					case 'SF_LOCKER_DELIVERY': {
						if (!cart.value.shipment.address) return false
						break
					}
					case 'SF_EXPRESS_DELIVERY': {
						if (!cart.value.shipment.address) return false
						break
					}
					default: {
						return false
					}
				}
			}
			return true
		})

		const error = ref(null)
		const isLoading = computed(() => store.state.loadingState)

		const showServicePopup = ref(false)

		// function cartProducts() {
		// 	return [...cart.products.filter(i => i.status == 'CLOSED')]
		// }

		function getProduct(val) {
			let result = null
			catalog.value.forEach((c) =>
				c.categories.forEach((category) =>
					category.products.find((p) => {
						if (p.id !== val.id) return
						result = p
					})
				)
			)
			return result
		}

		function setCurrentProduct(val, index) {
			const p = getProduct(val)
			product.value = p
			currentProduct.value = val
			return router.push(`/products/${val.id}?index=${index}`)
		}

		function setCurrentDiscount(val, index) {
			voucher.value = vouchers.value.find((voucher) => voucher.id == val.id)
			return router.push(`/discounts/${val.id}?index=${index}`)
		}

		async function toggleIsUnpacked() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				console.log(`toggleIsUnpacked`)
				console.log({ cart: currentCart.value })
				if (currentCart.value.pickupSetting) {
					currentCart.value.pickupSetting.isUnpacked = !currentCart.value.pickupSetting.isUnpacked
					await saleApi.updateSalePickupSetting(currentCart.value.id, { pickupSetting: currentCart.value.pickupSetting })
				}
				if (currentCart.value.deliverySetting) {
					currentCart.value.deliverySetting.isUnpacked = !currentCart.value.deliverySetting.isUnpacked
					await saleApi.updateSaleDeliverySetting(currentCart.value.id, { deliverySetting: currentCart.value.deliverySetting })
				}
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function minusQuantity(product, index) {
			try {
				const orderedQuantity = product.orderedQuantity - 1
				if (orderedQuantity < 1) return

				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				const saleProductRequest = {
					id: product.id,
					variantId: product.variantId,
					sellingPrice: product.sellingPrice,
					orderedQuantity: orderedQuantity,
				}

				await saleApi.updateSaleProduct(currentCart.value.id, index, saleProductRequest)
				cart.value.products[index].orderedQuantity = orderedQuantity

				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function addQuantity(product, index) {
			try {
				const orderedQuantity = product.orderedQuantity + 1
				if (orderedQuantity > 99) return

				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				const saleProductRequest = {
					id: product.id,
					variantId: product.variantId,
					sellingPrice: product.sellingPrice,
					orderedQuantity: orderedQuantity,
				}
				await saleApi.updateSaleProduct(currentCart.value.id, index, saleProductRequest)
				cart.value.products[index].orderedQuantity = orderedQuantity

				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				if (error.value.message.includes('STO001')) {
					error.value.message = t('error.STO001')
				}
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function removeProductFromCart(index) {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await saleApi.cancelSaleProduct(currentCart.value.id, index)
				// await store.dispatch('removeProductFromCart', { product: product })
				error.value = null
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e.message ?? e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function removeDiscountFromCart(index) {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await saleApi.cancelSaleVoucher(currentCart.value.id, index)
				// await store.dispatch('removeDiscountFromCart', { discount: discount })
				error.value = null
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function minusUsedPoints() {
			try {
				if (currentCart.value.usedPoints == 0) return
				store.commit('setLoadingState', { loadingState: true })
				error.value = null

				await saleApi.updateSaleUsedPoints(currentCart.value.id, { usedPoints: currentCart.value.usedPoints - 1 })

				// if (cart.value.usedPoints > 0) await store.dispatch('setUsedPointsInCart', { usedPoints: cart.value.usedPoints - 1 })
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function addUsedPoints() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await saleApi.updateSaleUsedPoints(currentCart.value.id, { usedPoints: currentCart.value.usedPoints + 1 })
				// if (cart.value.usedPoints < Math.floor(customer.value.pointBalance, -1)) await store.dispatch('setUsedPointsInCart', { usedPoints: cart.value.usedPoints + 1 })
				// let maxUsedPoint = cart.value.productTotal + cart.value.serviceTotal
				// if (cart.value.usedPoints > maxUsedPoint) await store.dispatch('setUsedPointsInCart', { usedPoints: maxUsedPoint })
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function updatedUsedPoints(points) {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				console.log('updatedUsedPoints', points)
				// await saleApi.updateSaleUsedPoints(currentCart.value.id, { usedPoints: currentCart.value.usedPoints + 1 })
				if (points < Math.floor(customer.pointBalance, -1)) await saleApi.updateSaleUsedPoints(currentCart.value.id, { usedPoints: points })
				console.log('log1')
				// await store.dispatch('setUsedPointsInCart', { usedPoints: points })
				let maxUsedPoint = cart.value.productTotal + cart.value.surchargeTotal
				if (points > Math.floor(customer.pointBalance, -1) && Math.floor(customer.pointBalance, -1) < maxUsedPoint) await saleApi.updateSaleUsedPoints(currentCart.value.id, { usedPoints: Math.floor(customer.pointBalance, -1) })
				if (points > Math.floor(customer.pointBalance, -1) && Math.floor(customer.pointBalance, -1) > maxUsedPoint) await store.dispatch('setUsedPointsInCart', { usedPoints: maxUsedPoint })

				// if (points > Math.floor(customer.value.pointBalance, -1) && Math.floor(customer.value.pointBalance, -1) < maxUsedPoint) await store.dispatch('setUsedPointsInCart', { usedPoints: Math.floor(customer.value.pointBalance, -1) })
				// if (points > Math.floor(customer.value.pointBalance, -1) && Math.floor(customer.value.pointBalance, -1) > maxUsedPoint) await store.dispatch('setUsedPointsInCart', { usedPoints: maxUsedPoint })
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function setCartRemark(remark) {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				console.log('setCartRemark', remark)
				await saleApi.updateSaleRemark(currentCart.value.id, { remark })
				// await store.dispatch('setCartRemark', { remark: remark })
				// await store.dispatch('getCart')
				error.value = null
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function checkoutCart() {
			try {
				store.commit('setLoadingState', { loadingState: true, refreshState: true })
				error.value = null
				// 檢查外送內容
				if (currentCart.value.method.type == 'DELIVERY') {
					if (!currentCart.value.deliverySetting) throw t('cart.shippingError')
					if (!currentCart.value.deliverySetting.address) throw t('cart.addressError')
					if (!currentCart.value.deliverySetting.time) throw t('cart.timeError')
				}
				// 建立PayPal訂單
				paypalData.value = await saleApi.paypalCheckout(currentCart.value.id)
				const saleId = currentCart.value.id
				let checkoutUrl = paypalData.value.links[1].href + '&locale.x='
				if (locale.value === 'TC') checkoutUrl += 'zh_HK'
				else if (locale.value === 'SC') checkoutUrl += 'zh_CN'
				else checkoutUrl += 'en_US'
				console.log(paypalData.value)
				await Browser.open({ url: checkoutUrl })
				Browser.addListener('browserFinished', async () => {
					try {
						await Browser.removeAllListeners()
						await saleApi.paypalCapture(saleId, { paypalId: paypalData.value.id })
						getCurrentSale(saleId)
						resetCart()
						setCurrentCartMethod(currentShop.value.saleSetting.methods[1])
						router.push(`/account/orders/${saleId}`)
						store.commit('setLoadingState', { loadingState: false, refreshState: false })
					} catch (e) {
						console.error(e)
						error.value = t('cart.payPalErrorMessage')
						store.commit('setLoadingState', { loadingState: false, refreshState: false })
						setTimeout(() => {
							error.value = null
						}, 10000)
					}
				})
			} catch (e) {
				console.error(e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false, refreshState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		function onCloseServicePopup() {
			showServicePopup.value = false
		}

		function callSupport() {
			chatMessage.value = `${t('orderStatusActions.ON_HOLD')} -  ${currentCart.value.id}`
			router.push('/account/chat')
		}

		return {
			callSupport,
			currentLang,
			isOnHold,
			moment,
			cart,
			customer,
			currentCart,
			deliveryType,
			isValid,
			error,
			isLoading,
			saleMethod,
			getProduct,
			setCurrentProduct,
			setCurrentDiscount,
			toggleIsUnpacked,
			getVariantById,
			cartProducts,
			cartDiscounts,
			minusQuantity,
			addQuantity,
			removeProductFromCart,
			removeDiscountFromCart,
			minusUsedPoints,
			addUsedPoints,
			setCartRemark,
			checkoutCart,
			showServicePopup,
			onCloseServicePopup,
			updatedUsedPoints,
			deliverySetting,
		}
	},
}
</script>
